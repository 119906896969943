import { Routes } from '@angular/router';

export const publicRoutes: Routes = [
  { path:   '', loadChildren: () => import('app/view/public/public.module').then(m => m.PublicModule) },
  { path:   '**', redirectTo: '', pathMatch: 'full' },
];

export const privateRoutes: Routes = [
  { path:   '', loadChildren: () => import('app/view/private/private.module').then(m => m.PrivateModule) },
  { path:   '**', redirectTo: '', pathMatch: 'full' },
];

export const restrictedRoutes: Routes = [
  { path:   '', loadChildren: () => import('app/view/restricted/restricted.module').then(m => m.RestrictedModule) },
  { path:   '**', redirectTo: '', pathMatch: 'full' },
];

export const adminRoutes: Routes = [
  { path:   '', loadChildren: () => import('app/view/admin/admin.module').then(m => m.AdminModule) },
  { path:   '**', redirectTo: '', pathMatch: 'full' },
];

export const partnerRoutes: Routes = [
  { path:   '', loadChildren: () => import('app/view/partner/partner.module').then(m => m.PartnerModule) },
  { path:   '**', redirectTo: '', pathMatch: 'full' },
];

export const administratorRoutes: Routes = [
  { path:   '', loadChildren: () => import('app/view/administrator/administrator.module').then(m => m.AdministratorModule) },
  { path:   '**', redirectTo: '', pathMatch: 'full' },
];

export const voidRoutes: Routes = [
  { path:   '**', loadChildren: () => import('app/view/void/void.module').then(m => m.VoidModule) },
];