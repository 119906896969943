import _                                   from 'lodash';

import { Options, SourceData             } from './source.interface'

function toSafeLowerCase(x: unknown) {
  return _.isString(x) ? x.toLowerCase() : x;
}

export function _sort<T extends SourceData>([docs, ...filters]: [null | T | T[], ...Options.Mapped[]]):[null | T | T[], ...Options.Mapped[]] {
  // must be an array
  if ( ! Array.isArray(docs)) return [docs, ...filters];

  // if sort is defined, apply it
  const sort = filters.find(filter => filter.sort)?.sort;
  if ( ! sort) return [docs, ...filters];

  // need to clone the array to avoid mutating the original
  const _docs = [...docs];

  const { direction, active } = sort;
  switch (direction) {
    case 'asc':  return [_.orderBy(_docs, [x => toSafeLowerCase(_.get(x, active)), 'createdAt', 'id'], ['asc',  'desc', 'asc']),  ...filters];
    case 'desc': return [_.orderBy(_docs, [x => toSafeLowerCase(_.get(x, active)), 'createdAt', 'id'], ['desc', 'asc',  'desc']), ...filters];
    default:     return [_docs, ...filters];
  }
}
