import { Component,
         Input,
         OnDestroy,
         ChangeDetectionStrategy,
         ViewChildren,
         QueryList,
         signal,
         computed,
         ChangeDetectorRef               } from '@angular/core';

import { MatCheckboxChange               } from '@angular/material/checkbox';
import { MatSlideToggleChange            } from '@angular/material/slide-toggle';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { Subject,
         Observable,
         fromEvent,
         combineLatest,
         tap,
         BehaviorSubject,
         Subscription                    } from 'rxjs';
import { map,
         take,
         takeUntil,
         switchMap,
         filter,
         debounceTime                    } from 'rxjs/operators';
import { CdkVirtualScrollViewport        } from '@angular/cdk/scrolling';
import _                                   from 'lodash';
import $                                   from 'jquery';

import { SearchComponent                 } from '@app/shared/components';

import { Teacher,
         Group,
         Person                          } from '@shared/interfaces';
import { UserPreferencesService          } from '@app/core';
import { ShallowMap,
         Util                            } from '@app/common';
import { DefectType                      } from '@app/mirror/types';

import { FormFieldComponent              } from '../form-fields.interface';

export type Types = (Group.populated | Person.populated | Teacher.populated);
export type Value<T extends Types = Types> = ({ to: T, exclude?: Person.populated[] } | T);
export type Type = 'groups' | 'persons' | 'teachers';

@Component({
  selector: 'app-form-field-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsComponent implements OnDestroy,
                                        FormFieldComponent {
  @ViewChildren(CdkVirtualScrollViewport) scroll: QueryList<CdkVirtualScrollViewport>;
  @ViewChildren(SearchComponent)          search: QueryList<SearchComponent>;

  private _selectedList        = new Subject<BehaviorSubject<Types[] | null> | Observable<Types[]>>();

  protected primarySelection   = new BehaviorSubject(new Set<string>());
  protected coalescedSelection = new BehaviorSubject(new Set<string>());
  protected selection          = this.primarySelection;

  protected override           = new BehaviorSubject<Types[] | null>(null);
  protected onSearch           = new BehaviorSubject<string>('');
  protected onGroupSelect      = new BehaviorSubject<string[]>([]);
  protected filtered           = new BehaviorSubject<Types[][]>([]);
  protected isQuasiSelected    = signal(new Map<string, boolean>())
  protected coalescedState     = signal(new ShallowMap<string, number>());
  protected availabilityMap:  BehaviorSubject<Map<string, DefectType>> | null;

  protected computing          = signal(false);

  public onDestroy             = new Subject<boolean>();

  protected indeterminate = combineLatest([
    this.filtered.pipe(map(x => x?.flat().length)),
    combineLatest([
      this.primarySelection.pipe(map(x => x.size)),
      this.coalescedSelection.pipe(map(x => x.size)),
    ]).pipe(
      map(([primary, coalesced]) => this.isCoalescedSelection() ? coalesced : primary)
    )
  ]).pipe(
    map(([total, selected]) => total != selected && selected != 0),
    takeUntil(this.onDestroy)
  );

  protected checked = combineLatest([
    this.filtered.pipe(map(x => x?.flat().length)),
    combineLatest([
      this.primarySelection.pipe(map(x => x.size)),
      this.coalescedSelection.pipe(map(x => x.size)),
    ]).pipe(
      map(([primary, coalesced]) => this.isCoalescedSelection() ? coalesced : primary)
    )
  ]).pipe(
    map(([total, selected]) => total && total == selected),
    takeUntil(this.onDestroy)
  );

  constructor(
    protected readonly preferences:      UserPreferencesService,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    this.selection = this.primarySelection;
    /*
      Set coalesced types
    */
    if (this._coalescedList) {
      this._coalescedList
      .pipe(takeUntil(this.onDestroy))
      .subscribe(list => {
        this._coalescedMap = new Map(list?.map(x => ([x.id, x])));
      });

      combineLatest([
        this._list.pipe(filter(x => x != null), take(1)),
        this._coalescedList.pipe(filter(x => x != null), take(1))
      ])
      .pipe(
        take(1),
        takeUntil(this.onDestroy),
        debounceTime(0)
      ).subscribe(() => {
        this._setCoalescedSelection();
      });

      this._coalescedValue?.filter(x => _.has(x, ['to', 'group'])).forEach(val => {
        const group = this._list.value?.find(x => x.id == _.get(val, ['to', 'group', 'id']));
        if (group)
          this.isQuasiSelected.update(x => x.set(group.id, true));
      });
    }

    const set = this.primarySelection.value;
    this._value?.map(x => 'to' in x ? x.to : x) .forEach(x => set.add(x.id));
    this.primarySelection.next(set);

    combineLatest([
      this.onSearch.pipe(map(x => x?.toLowerCase())),
      this._selectedList.pipe(switchMap((z) => z), map(x => _.sortBy(x, ['displayName', 'createdAt']))),
      this.override.pipe(map(x => x != null ? _.sortBy(x, [x => x?.displayName?.toLowerCase()], ['displayName', 'createdAt']) : x)),
      this.onGroupSelect,
      this.numColumns,
    ]).pipe(
      tap(() => this.computing.set(true)),
      map(([search, groups, override, selected]) => {
        if (! (search || selected.length))
          return override ?? groups;
        const searchArr = search.split('+') ?? [];
        return (override ?? groups)
        ?.filter((value: Types) => {
          if (! ('group' in value) || ! selected.length) return true;
          return selected.includes(value.group?.id ?? '');
        })
        ?.filter((value: Types) => {
          return searchArr.some(s => {
            if ('group' in value && ((value.group?.displayName ?? '')).toLowerCase().includes(s))
              return true;

            if ('SSN' in value && value.SSN?.value?.toLowerCase().includes(s))
              return true;

            return (value.displayName ?? '').toLowerCase().includes(s);
          });
        });
      }),
      // Map array to set of 2 values [value1, value2]
      map(x => x?.reduce((acc, val, i) => {
        i % this.numColumns.value == 0 ? acc.push([val]) : acc[acc.length - 1].push(val);
        return acc;
      }, [] as Types[][])),
      tap(() => this.computing.set(false)),
      takeUntil(this.onDestroy)
    ).subscribe(this.filtered);

    /*
      Set current filter to primary type
    */

    this.selectionType.set(this.type);
    this._selectedList.next(this._list);

    this.scrollToSelected();
  }

  protected paste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text/plain');

    if ( ! pastedText) return;

    // there must be a newline in the clipboard data
    if ( ! pastedText.includes('\n')) return;

    const searchStr = pastedText.split('\n').map(x => x.trim()).filter(Boolean).join('+');
    if (this.search.first)
      this.search.first.value = searchStr;

    event.preventDefault();
  }

  protected clearValue() {
    this._value = [];
    this._coalescedValue = [];
    this._setPrimarySelection();
    this._setCoalescedSelection();
    this.inheritValue.set(false);
    this.pristine.set(false);
  }

  protected resetValue(): void {
    this.override.next(null);
    this._value = this._pristineValue;
    this._coalescedValue = this._pristineCoalescedValue;
    this.inheritValue.set(this._pristineInheritValue);
    this._setPrimarySelection();
    this._setCoalescedSelection();
    this.pristine.set(true);
  }

  protected setList(type?: Type) {
    this.override?.next(null);
    if (! type || this._coalescedType() != type) {
      this.selectionType.set(this.type);
      this.selection = this.primarySelection;
      return this._selectedList.next(this._list);
    }

    this.selectionType.set(type);
    this.selection = this.coalescedSelection;

    // Set the coalesced list to the the members of the selected groups
    if (this.constrictCoalesced) {
      return this._selectedList.next(
        this.primarySelection.pipe(
          map(x => {
            const arr: Types[] = [];
            for (const id of Array.from(x)) {
              const val = this._map?.get(id);
              if (val && 'members' in val) {
                val.members?.forEach(x => arr.push(this._coalescedMap?.get(x.id)!));
              }
            }

            return arr;
          })
        )
      )
    }

    return this._selectedList.next(this._coalescedList);
  }

  protected filterSelected() {
    if (this.override?.value != null)
      return this.override.next(null);
    if (! this.selection?.value)
      return;
    const map = this.isCoalescedSelection() ? this._coalescedMap : this._map;
    const selected = [...this.selection?.value].map(x => map?.get(x)).filter(Boolean);
    this.override.next(selected);
  }

  protected toggleAll(event: MatCheckboxChange) {
    const { checked } = event;

    this.filtered?.value.flat().forEach(x => this._updateState(x, checked));

    this._setValue();
  }

  protected dragStart(row: number, col: number, selected: boolean) {
    const index = row * this.numColumns.value + col;
    if (! this.isMultiple()) {
      //deselect the selected value if not same as toggled
      if (! this.selection.value.has(this.filtered.value?.flat()?.[index]?.id))
        this.selection.next(new Set());
      const value = this.filtered.value?.flat()?.[index];
      if (! this._allowDeselect() && this.selection.value.has(value.id))
        return;
      this._updateState(value, ! this.selection.value.has(value.id));
      this._setValue();
      return;
    }
    this._dragStart = [row, col, row * this.numColumns.value + col];
    this.pseudoState.set(! selected);
    this.pseudoSelected.update(x => x.add(index));
    fromEvent(window, 'mouseup')
    .pipe(
      take(1),
      takeUntil(this.onDestroy)
    )
    .subscribe((x) => this._dragEnd(x));
  }

  protected dragEnter(row: number, col: number) {
    const index = row * this.numColumns.value + col;
    if (! this._dragStart) return;
    // Toggle all between start and end
    // if column have changed toggle the new column as well
    const colDiff = col - this._dragStart[1];
    const selected = [];
    // set start index to smallest index
    let i = Math.min(Math.min(this._dragStart[2], this._dragStart[2] + colDiff), index);
    for (i; i <= Math.max(this._dragStart[2], index); i += this.numColumns.value) {
      selected.push(...Array(Math.abs(colDiff) + 1).fill(i).map((x, step) => x + step));
    }
    this.pseudoSelected.set(new Set(selected));
  }

  private _dragEnd(e: Event) {
    if (! this._dragStart) return;

    this.pseudoSelected().forEach(x => {
      if (this.filtered.value?.flat()?.[x])
        this._updateState(this.filtered.value?.flat()?.[x], this.pseudoState());
    });
    this.pseudoSelected?.update(x => {
      x.clear();
      return x
    });
    delete this._dragStart;
    // this._setCoalescedSelection();
    this._setValue();
  }
  private _dragStart?: [row: number, col: number, index: number];
  protected pseudoSelected = signal<Set<number>>(new Set());
  protected pseudoState = signal<boolean>(false);

  /**
   *
   * @param value The toggled entity
   * @param state Whether the entity is selected or not
   * @description Update the entire selection state and value
   */
  private _updateState(value: Types, state?: boolean) {
    this.inheritValue.set(false);
    {
      const set = this.selection.value;
      set[state ? 'add' : 'delete'](value.id);
      this.selection.next(set);
    }

    if (this._coalescedList) scope: {

      if (this.selectionType() == this._type) {
        /*
          toggled primary type
          this value cant be quasi selected anymore
          select all children to the primary vertex
        */
        const action = state ? 'add' : 'delete';
        this.isQuasiSelected.update(x => x.set(value.id, false));

        if (this.constrictCoalesced) {
          this.coalescedSelection.next(new Set());
          this.coalescedState.set(new ShallowMap());
        }

        if (this._coalescedList?.value && 'members' in value) {
          this.coalescedState.update(y => {
            const set = this.coalescedSelection.value;
            value.members?.forEach((x: Person.populated) => {
              // if (this.constrictCoalesced)
              // this._coalescedList.push(this._coalescedMap!.get(x.id)!);
              const prevState = !!y.get(x.id);
              // Pull the group from the map and set quasi selected status
              y.init(x.id, 0);
              y.set(x.id, y.get(x.id)! + (state ? 1 : -1));
              if (prevState != !!y.get(x.id))
                set[action](x.id);
            });
            this.coalescedSelection.next(set);
            return y;
          });
        }

        break scope;
      }

      /*
        toggled secondary type
        should primary vertex be quasi selected
        is parent empty: deselect(meby?)
      */
      if (! ('group' in value) || ! value.group)
        break scope;

      /*
        should primary vertex be quasi selected
        quasi select parent if child is selected and parent is not
      */
      if (this.selection.value?.has(value.id) && ! this.primarySelection.value.has(value.group.id)) {
        this.isQuasiSelected.update((x) => x.set(value.group!.id, true));
        break scope;
      }
      /*
        if this is the last child to be deselected with the parent being quasi selected
        remove quasi selected status from parent
      */
      const quasiSelected = Array.from(this.isQuasiSelected().entries()).filter(([key, value]) => value).map(([key]) => key);
      if (! quasiSelected.map(x => this._map?.get(x)).filter(Boolean).some(x => 'members' in x ? x.members?.some(y => y.id == value.id) : false))
        break scope;

      this.isQuasiSelected.update((x) => x.set(value.group!.id, false));
    }
  }

  public inheritToggle(event: MatSlideToggleChange) {
    this.inheritValue.set(event.checked);
    this._setValue();
  }

  private _serialize(x: Extract<Value, { to: object }>) {
    return _.pickBy({
      to: x.to.id,
      exclude: x.exclude?.map(({ id }) => id).sort() || null
    }, x => Array.isArray(x) ? x.length : _.identity(x));
  }

  private _allowDeselect () {
    return ! this.isMultiple() && this.nullable;
  }

  protected scrollToSelected (
  ) {
    // scroll only if single select
    if (this.isMultiple()) return;

    // must have a value
    const val = [...this.selection.value].at(0);
    if ( ! val) return;

    this.scroll.changes
    .pipe(
      take(1),
      takeUntil(this.onDestroy)
    )
    .subscribe((x: QueryList<CdkVirtualScrollViewport>) => {
      // abort if no scroll
      const scroll = x.first;
      if ( ! scroll) return;

      // abort if not found
      const index = this.filtered.value?.flat().findIndex(x => x.id == val);
      if (index == -1) return;

      setTimeout(() => {
        const height = $(scroll.elementRef.nativeElement).height() ?? 0;
        const offset = Math.max(0, index * 48 - (height - 48));
        scroll.scrollToOffset(offset);

        // sometimes the scroll is not updated correctly so trigger a scroll event to sort it out
        const $scroll = $(scroll.elementRef.nativeElement);
        $scroll.scrollTop(($scroll.scrollTop() ?? 0) + 1);
      }, 0)
    });
  }

  /**
   * @description Set the value of the form field form the current selection
   */
  private _setValue(): void {
    scope: {
      if (this.inherit && this.inheritValue()) {
        this._value          = this._pristineInheritValue === true ? this._pristineValue          : null;
        this._coalescedValue = this._pristineInheritValue === true ? this._pristineCoalescedValue : null;
        break scope;
      }

      this._value = [...this.primarySelection.value]
      .filter(x => this._map?.has(x))
      .map(to => {
        const val = this._map?.get(to)!;
        if (this.flat)
          return val;

        let exclude: Person.populated[] | undefined;
        if (this.coalescedSelection && val && 'members' in val) {
          exclude = _.get(val, 'members')?.filter(member => ! this.coalescedSelection.value?.has(member.id));
        }

        return { to: val, ...exclude?.length && { exclude } };
      });
      /*
        Find all coalesced with a non selected group(_coalescedState is 0)
      */
      this._coalescedValue = [
        ...(this.coalescedSelection.value ?? [])
      ].filter(x => !this.coalescedState().get(x))
      .map(to => ({ to: this._coalescedMap?.get(to)! })) ?? []
    }

    if (this.override?.value && this.selection) {
      const map = this.isCoalescedSelection() ? this._coalescedMap : this._map;
      this.override.next([...this.selection.value].map(x => map?.get(x)).filter(Boolean));
    }

    this.pristine.set(this._isPristine());
    this._changeDetectorRef.markForCheck();
  }

  private _isPristine(): boolean {
    return this._pristineInheritValue === this.inheritValue() && _.isEqual(
      _.orderBy(this._value?.map(x => 'to' in x ? this._serialize(x) : {to: x.id}), 'to'),
      _.orderBy(this._pristineValue?.map(x => 'to' in x ? this._serialize(x) : {to: x.id}), 'to')
    ) && (this._coalescedList?.value ? _.isEqual(
      _.orderBy(this._coalescedValue?.map(x => 'to' in x ? this._serialize(x) : {to: x.id}), 'to'),
      _.orderBy(this._pristineCoalescedValue?.map(x => 'to' in x ? this._serialize(x) : {to: x.id}), 'to')
    ) : true);
  }

  private _setPrimarySelection() {
    this.primarySelection.next(new Set(this._value?.map(x => 'to' in x ? x.to.id : x.id) ?? []));
  }

  private _setCoalescedSelection() {
    this.coalescedState.update(state => {
      this.coalescedState.set(new ShallowMap());
      const selection = new Set<string>();
      this._value?.forEach((selected) => {
        if (! ('to' in selected))
          return;

        const val = this._map?.get(selected.to.id);
        if (val && 'members' in val) {
          val.members?.forEach(x => {
            state.init(x.id, 0);
            state.set(x.id, (state.get(x.id) ?? 0) + 1);

            if (selected.exclude?.some(z => z.id == x.id))
              return;

            selection.add(x.id);
          })
        }
      });

      const coalescedValue = this._coalescedValue?.map(x => 'to' in x ? x.to : x).map(x => this._coalescedMap?.get(x.id)).filter(Boolean) ?? [];
      // Select all persons that have been selected explicitly
      coalescedValue?.forEach(x => selection.add(x.id));

      coalescedValue
      ?.filter(x => _.has(x, ['group']))
      .forEach(val => {
        const group = this._map?.get(_.get(val, ['group', 'id']));
        if (group && ! this.primarySelection.value.has(group.id))
          this.isQuasiSelected.update(x => x.set(group.id, true));
      });
      this.coalescedSelection.next(selection);
      return state;
    });
  }


  protected isCoalescedSelection = computed<boolean>(() => this.selectionType() != this.type);

  get empty() {
    return ! this.inheritValue() && !! this.selection.value.size;
  }

  public pristine = signal<boolean>(true);

  @Input() groups: BehaviorSubject<Group[]>;

  @Input()
  set list(value: BehaviorSubject<Types[] | null>) {
    if (! (value instanceof BehaviorSubject))
      return;

    this._list = value;
    this.loading = value.pipe(map(x => x == null));
    this.isEmpty = value.pipe(map(x => x?.length == 0));
    this._listSubscription?.unsubscribe();
    this._listSubscription = this._list
    .pipe(takeUntil(this.onDestroy))
    .subscribe(list => {
      this._map = new Map(list?.map(x => ([x.id, x])));
    });
  }
  private _list = new BehaviorSubject<Types[] | null>([]);
  private _listSubscription: Subscription;
  private _map?:  Map<string, Types>;
  protected loading: Observable<boolean>;
  protected isEmpty: Observable<boolean>;

  get numCols(): number { return this.numColumns.value; }
  set numCols(value: number) {
    if (value == this.numColumns.value) return;
    this.numColumns.next(value ?? 2);
  }
  protected numColumns = new BehaviorSubject<number>(2);

  set coalescedList(value: BehaviorSubject<Types[] | null>) {
    if (! value) return;
    this._coalescedList = value;
  }
  private _coalescedList: BehaviorSubject<Types[] | null>;
  private _coalescedMap?:  Map<string, Types>;

  get constrictCoalesced(): boolean { return this._constrictCoalesced; }
  set constrictCoalesced(value: boolean | string) {
    this._constrictCoalesced = coerceBooleanProperty(value);
  }
  private _constrictCoalesced = false;

  get nullable(): boolean { return this._nullable; }
  set nullable(value: boolean | string) {
    this._nullable = coerceBooleanProperty(value);
  }
  private _nullable = true;

  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
  }
  private _inherit:    boolean;
  public _pristineInheritValue: boolean;
  public inheritValue = signal<boolean>(false);

  get multiple(): boolean { return this._multiple(); }
  set multiple(value: boolean | string) {
    this._multiple.set(coerceBooleanProperty(value));
  }
  private _multiple = signal<boolean>(true);

  get flat(): boolean { return this._flat; }
  set flat(value: boolean | string) {
    this._flat = coerceBooleanProperty(value);
  }
  private _flat: boolean = false;

  get type(): Type { return this._type; }
  set type(value: Type) {
    this._type = value;
    this.selectionType.set(value);
  }
  private _type: Type;

  protected selectionType = signal<Type | undefined>(undefined);

  protected isMultiple = computed<boolean>(() => this._multiple() || this.isCoalescedSelection());
  protected hasMultiple = computed<boolean>(() => this._multiple() || this.hasCoalesced());

  get coalescedType(): Type | undefined { return this._coalescedType(); }
  set coalescedType(value: Type) {
    this._coalescedType.set(value);
  }
  private _coalescedType = signal<Type | undefined>(undefined);
  protected hasCoalesced = computed<boolean>(() => this._coalescedType() != undefined);

  get value(): Value | Value[] | null {
    return this._value == null ? null : this.multiple ? this._value : (this._value[0] ?? null);
  }
  set value(_val: Value | Value[] | null) {
    this._value = this._pristineValue = _.clone(Array.isArray(_val) ? _val : _val == null ? null: [_val]);
    this._setPrimarySelection();
    this._setCoalescedSelection();

    this.inheritValue.set(_val === null)
    this._pristineInheritValue = this.inheritValue();
  }
  private _value:         Value[] | null;
  private _pristineValue: Value[] | null;

  get inheritedValue(): Value[] | null { return this._inheritedValue; }
  set inheritedValue(value: Value | Value[] | null) {
    this._inheritedValue = Array.isArray(value) ? value : value == null ? null : [value];

    if (this.inheritValue() !== true)
      return;

    this._value = this._pristineValue = this._inheritedValue;

    this._setPrimarySelection();
    this._setCoalescedSelection();
  }
  private _inheritedValue: Value[] | null;

  get coalescedValue(): Value[] | null { return this._coalescedValue; }
  set coalescedValue(value: Value | Value[] | null) {
    this._coalescedValue = this._pristineCoalescedValue = value ? Util.functions.coerceArrayProperty(value) : null;
    this._setCoalescedSelection();
  }
  private _coalescedValue:         Value[] | null;
  private _pristineCoalescedValue: Value[] | null;

  get inheritedCoalescedValue(): Value[] | null { return this._inheritedCoalescedValue; }
  set inheritedCoalescedValue(value: Value | Value[] | null) {
    this._inheritedCoalescedValue = Array.isArray(value) ? value : value == null ? null : [value];
    if (this.inheritValue() !== true)
      return;

    this._coalescedValue = this._pristineCoalescedValue = this._inheritedCoalescedValue;
    this._setCoalescedSelection();
  }
  private _inheritedCoalescedValue: Value[] | null;

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.override?.complete();
    this.onSearch?.complete();
    this.onGroupSelect?.complete();
  }
}