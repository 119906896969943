import { EnvironmentInjector,
         Renderer2,
         Injectable,
         Injector,
         ViewContainerRef,
         createComponent,
         ApplicationRef,
         ComponentRef,
         OnDestroy,
         RendererFactory2,
         ElementRef,
         Component,
         Optional,
         Self,
         Input                           } from '@angular/core';
import { MatFormField,
         MatFormFieldControl             } from '@angular/material/form-field';
import { AbstractControl,
         ControlValueAccessor,
         FormControl,
         NgControl                       } from '@angular/forms';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { BehaviorSubject,
         Subject,
         fromEvent,
         takeUntil                       } from 'rxjs';
import _                                   from 'lodash';
import $                                   from 'jquery';

import { LoggerService,
         SourceService                   } from '@app/core';

import { Util                            } from '@app/common';

import { SourceCore                      } from '@app/core/source/source.core';
import { Collection                      } from '@app/core/source/source.interface';

import { SharedService                   } from '@app/view/private/schedule/sub-pages/sub-pages.service';

import { DefectType                      } from '@app/mirror/types';

import { State                           } from './form-fields.types';
import { getComponentState,
         getDisplayNameComponent,
         getComponentRef                 } from './form-fields.utils';
import { FormFieldComponent              } from './form-fields.interface';

@Injectable()
export class FormFieldsService {
  protected _viewContainerRef: ViewContainerRef;

  private _onDestroy:          Subject<void> = new Subject<void>();
  private _resizeObserver:     ResizeObserver;
  private _windowObserver:     ResizeObserver;
  private _expanded:           JQuery<HTMLElement> | undefined;

  private _renderer:           Renderer2;
  private _listenerFunction:   ReturnType<Renderer2['listen']>;

  private _componentRef?:      ComponentRef<FormFieldComponent>;

  private _sourceElement:      ElementRef;

  private _forms = new Map<string, FormControlComponent>();

  private _id?:                string[];
  private _state?:             State;
  private _collection?:        Collection;
  private _path?:              string;
  private _coalescedPath?:     string;
  private _control?:           FormControl | AbstractControl;
  private _coalescedControl?:  FormControl | AbstractControl;
  private _formControl?:       FormControlComponent;

  private _source?:            SourceService;

  constructor(
    private readonly _rendererFactory:        RendererFactory2,
    private readonly _appRef:                 ApplicationRef,
    private readonly _environmentInjector:    EnvironmentInjector,
    private readonly _injector:               Injector,
    private readonly _shared:                 SharedService
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  /**
   * @description             Get all the form controls
   */
  public getForm(formControlName: string) {
    return this._forms.get(formControlName);
  }

  /**
   * @param state             State of the component(ie groups, teachers, persons, etc)
   * @param element           Element that triggered the opening of the component
   * @param value             Value to be passed into the component
   * @description             Set the display value of the component
   */
  public setFormControl (
    element:             ElementRef,
    value:               any,
    matFormField:        MatFormField,
    formControlName:     string
  ): void {
    const wrapperElem: HTMLElement = this._renderer.createElement('div');

    // Create the form control component
    const componentRef = createComponent(
      FormControlComponent,
      {
        environmentInjector: this._environmentInjector,
        elementInjector: this._injector,
        hostElement: wrapperElem,
    });
    Object.assign(componentRef.instance, { value });

    // this._forms.set(formControlName, componentRef.instance);

    matFormField._control = componentRef.instance;

    // set form attributes to the wrapper element
    if (formControlName)
      wrapperElem.setAttribute('formControlName', formControlName);
    wrapperElem.setAttribute('matInput','');

    // Insert element before the element
    $(element.nativeElement).parent().prepend(wrapperElem);

    // Attach the virtual form component to the view
    // This will ensure that the component is dirty checked.
    this._appRef.attachView(componentRef.hostView);
  }

  /**
   * @param state             State of the component(ie groups, teachers, persons, etc)
   * @param element           Element that triggered the opening of the component
   * @param value             Value to be passed into the component
   * @description             Set the display value of the component
   */
  public setDisplayValue (
    state:                    State,
    element:                  ElementRef,
    value:                    any,
    coalescedValue?:          any,
    inheritedValue?:          any,
    inheritedCoalescedValue?: any
  ): void {
    const component = getDisplayNameComponent(state);
    if (! component) {
      return element.nativeElement.innerHTML = value;
    }

    const componentRef = createComponent(
      component,
      {
        environmentInjector: this._environmentInjector,
        elementInjector: this._injector,
        hostElement: element.nativeElement,
    });

    const inherit = value === null;

    Object.assign(componentRef.instance, { value, coalescedValue, inheritedValue, inheritedCoalescedValue, inherit });

    this._appRef.attachView(componentRef.hostView);
  }

  /**
   * @param state             State of the component(ie groups, teachers, persons, etc)
   * @param id                Id of the document
   * @param element           Element that triggered the opening of the component
   * @param value             Value to be passed into the component
   * @param coalescedValue    Coalesced value to be passed into the component. ie participants
   * @param availabilityMap   Availability map for the component. Generated in mirror
   * @description             Opens the form field value editor.
   */
  public open (
    state:               State,
    id:                  string | string[],
    collection:          Collection,
    path:                string,
    coalescedPath:       string,
    flat:                boolean,
    element:             ElementRef,
    value:               any,
    control:             FormControl | AbstractControl,
    formControl:         FormControlComponent,
    coalescedValue:      any,
    coalescedControl:    FormControl | AbstractControl,
    availabilityMap:     BehaviorSubject<Map<string, DefectType> | null> | null,
    options:            {
      classList?: string[],
      parent?: {
        selector:  string,
        classList: string[]
      }
    } = {}
  ): void {
    /*
      Destroy all previous components.
    */
    this._destroy();

    const injector = Injector.create(
      {
        parent: this._injector,
        providers: [
          {
            provide: SourceService,
            deps: [
              SourceCore,
              LoggerService
            ]
          }
        ]
      }
    );

    this._source = injector.get(SourceService);
    this._id               = Util.functions.coerceArrayProperty(id);
    this._collection       = collection;
    this._path             = path;
    this._coalescedPath    = coalescedPath;
    this._control          = control;
    this._coalescedControl = coalescedControl;
    this._formControl      = formControl;
    this._state            = state;

    this._sourceElement = element;
    // this._viewContainerRef = _viewContainerRef;

    /**
     * Add classes to the source element.
     */
    if (options?.classList?.length) {
      options?.classList.forEach(className => $(element.nativeElement).addClass(className));
    }
    /**
     * Add classes to the source element parent with selector.
     */
    if (options?.parent) {
      const parent = $(element.nativeElement).closest(options.parent.selector);
      options.parent.classList.forEach(className => parent.addClass(className));
    }

    /**
     * Create anchor point for the element that triggered the event.
     * when available
     */
    // element.nativeElement.style['anchor-name'] = '--form-field';
    // element.nativeElement.id = 'form-field';

    /**
     * Create the wrapper element for the component.
     */
    // const wrapperElem: HTMLElement = this._renderer.createElement('div');
    const wrapperElem: HTMLElement = this._renderer.createElement('dialog');
    wrapperElem.classList.add('mat-elevation-z4') //, 'padding-inline', 'padding-bottom-8';
    wrapperElem.classList.add('white-bg');
    wrapperElem.classList.add('dialog-transparent-backdrop');
    wrapperElem.style['overflow'] = 'hidden';

    // FOR CYPRES: make dialog element listen to escape keydown event
    this._renderer.listen(wrapperElem, 'keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape') this.close();
    });


    /**
     * Create anchor for the expanded card to the element that triggered the event.
     * when available
     */
    // wrapperElem.style['bottom'] = 'anchor(--my-anchor top)';
    // wrapperElem.style['left'] = 'calc(anchor(--my-anchor center) - (var(--boat-size) * 0.5));';
    // wrapperElem.setAttribute('anchor', 'form-field');

    document.body.appendChild(wrapperElem);

    this._expanded = $(wrapperElem);

    this._expanded.css({
      width: 'inherit',
      height: 'inherit',
      'border-radius': '4px',
      'max-height': 'calc(100vh - 48px)',
      border: 'none',
      padding: 'unset',
      position: 'absolute',
      margin: 'unset'
    });


    /*
      Create the component and attach it to the view.
    */
    this._componentRef = createComponent(
      getComponentRef(state),
      {
        environmentInjector: this._environmentInjector,
        elementInjector: this._injector,
        hostElement: wrapperElem,
    });

    /*
      Assign the component parameters to the component instance.
    */
    // Object.assign(componentRef.instance, options.componentParameters ?? { });
    Object.assign(this._componentRef.instance, {
      value: control ? control.value : value,
      inherit: collection == 'events',
      ...coalescedValue  && { coalescedValue },
      ...availabilityMap && { availabilityMap }
    });
    const componentState = getComponentState(state, this._source, this._onDestroy, this._shared.did, flat, path);
    Object.assign(this._componentRef.instance, componentState);
    // this._renderer.appendChild(wrapperElem, domElem);
    /*
      Attach the created view such that it will be dirty checked.
    */
    this._appRef.attachView(this._componentRef.hostView);

    // console.log(this._getSourceContainer());
    /**
     * Set the position of the wrapper element.
     */
    this._setWrapperPosition();
    const dialog = document.querySelector('dialog');

    dialog?.showModal();
    if (dialog)
      fromEvent(dialog, 'click')
      .pipe(
        // debounceTime(100),
        takeUntil(this._onDestroy)
      )
      .subscribe((event: MouseEvent) => {
        const rect = dialog.getBoundingClientRect();
        if (event.clientY < rect.top || event.clientY > rect.bottom ||
            event.clientX < rect.left || event.clientX > rect.right) {
          dialog.close();
          this.close();
        }
      });

    this._componentRef.instance.onDestroy?.subscribe(() => this._componentRef?.destroy());
    const numCols = componentState.numCols;

    this._resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const {
          contentRect: {
            width,
            height,
          }
        } = entry;


        this._setWrapperPosition();
      }
    });
    // Add window resize observer to change the number of columns if screen size becomes smaller
    if (numCols != 1) {
      this._windowObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const {
            contentRect: {
              width,
            }
          } = entry;

          if (width < 900) {
            Object.assign(this._componentRef!.instance, { numCols: 1 });
          } else {
            Object.assign(this._componentRef!.instance, { numCols: 2 });
          }
        }
      });
      this._windowObserver.observe(window.document.body);
    }

    this._resizeObserver.observe(wrapperElem);
  }

  ngOnDestroy(): void {
    this._destroy();
  }

  /**
   * Position the wrapper element below the source element
   * and make sure the element fit the body
   */
  private _setWrapperPosition() {
    if (! this._expanded) return;
    const sourceContainer = this._getContainerProperties($(this._sourceElement?.nativeElement));
    if (! sourceContainer) return;

    const body = this._getContainerProperties($(window.document.body));
    const wrapper = this._getContainerProperties(this._expanded);

    if (! body) return;
    if (! wrapper) return;

    let top = sourceContainer.top + sourceContainer.height;
    let left = sourceContainer.left;

    if (top + wrapper.height > body.height) {
      top += body.height - (top + wrapper.height) - 24;
    }

    if (left + wrapper.width > body.width) {
      left += body.width - (left + wrapper.width) - 24;
    }

    this._expanded.css({
      top,
      left,
      opacity: 1
    });
  }

  private _getContainerProperties(container: JQuery<HTMLElement>) {
    if (! container) return;

    const top                = container.offset()!.top;
    const left               = container.offset()!.left;
    const width              = container.outerWidth(true)!;
    const height             = container.outerHeight(true)!;

    return {
      top,
      left,
      height,
      width
    };
  }

  /**
   * @description Closes the component
   */
  public close (): void {
    const minimizedState = this._expanded;
    /**
     * If the minimized state is not available, simply destroy the component.
     */
    if (! minimizedState) {
      this._destroy();
      return;
    }
    /*
      Clear all content of the component such that the animation become more smooth.
    */
    this._expanded?.empty();
    this._destroy();
  }

  private _destroy() {
    /**
     * If the state is not available, return.
     * nothing is available to destroy.
     */
    if (! this._state) return;
    /**
     * If the source is available, set the value ..
     */
    if (this._id && this._collection && this._path) sourceScope: {
      if (this._componentRef?.instance.pristine())
        break sourceScope;
      this._source?.set({ did: this._shared.did, collection: this._collection },
        this._id.map(id => ({
          [this._path!]: this._componentRef?.instance.value,
          ...this._coalescedPath && { [this._coalescedPath]: this._componentRef?.instance.coalescedValue },
          id
        }))
      );
    }

    /**
     * If control is supplied, set the value.
     */
    if (this._control && ! this._componentRef?.instance.pristine()) {
      this._control.patchValue(this._componentRef?.instance.value);
      this._control.markAsDirty();
    }
    /**
     * If form control accessor is supplied, set the value.
     */
    if (this._formControl && ! this._componentRef?.instance.pristine()) {
      this._formControl.value = this._componentRef?.instance.value;
    }

    /**
     * If coalesced control is supplied, set the value.
     */
    if (this._coalescedControl && ! this._componentRef?.instance.pristine()) {
      this._coalescedControl.patchValue(this._componentRef?.instance.coalescedValue);
      this._coalescedControl.markAsDirty();
    }

    /**
     * If value have been modified update the display value
     */
    if (this._componentRef?.instance.pristine() === false) {
      this.setDisplayValue(
        this._state!,
        this._sourceElement,
        this._componentRef?.instance.value,
        this._componentRef?.instance.coalescedValue
      );
    }
    /**
     * If the component is available, destroy it.
     * Also detach the view from the appRef.
     * Destroy the injector? if this is necessary. <----------------- TODO
     */
    if (this._componentRef?.hostView) {
      console.log('Ensure that the component destroy is called.')
      this._componentRef.destroy();
      this._appRef.detachView(this._componentRef.hostView);
    }
    /**
     * Remove the source and id from the service.
     */
    delete this._source;
    delete this._state;
    delete this._id;
    delete this._collection;
    delete this._path;
    delete this._coalescedPath;
    delete this._control;
    delete this._coalescedControl;
    delete this._formControl;
    /**
     * Remove classes from source element.
     */
    if (this._sourceElement)
      $(this._sourceElement.nativeElement).closest('td')
        .removeClass('outline-primary')
        .removeClass('border-radius');
    /**
     * Stop listen to the window click event.
     */
    this._listenerFunction?.();
    this._onDestroy.next();

    this._resizeObserver?.unobserve(window.document.body);
    this._expanded?.remove();
  }
}

@Component({
  template: '',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FormControlComponent,
      multi: true
    }
  ]
})
export class FormControlComponent implements OnDestroy,
                                             ControlValueAccessor,
                                             MatFormFieldControl<any> {
  private _onDestroy:   Subject<void>           = new Subject<void>();
  static nextId:        number                  = 0;
  public stateChanges:  Subject<void>           = new Subject<void>();
  public focused:       boolean                 = false;
  public errorState:    boolean                 = false;
  public controlType:   string                  = 'RS-input';
  public id:            string                  = `RS-input-${ FormControlComponent.nextId++ }`;
  public describedBy:   string                  = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  constructor(private _focusMonitor: FocusMonitor,
              private _elementRef:   ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .pipe(takeUntil(this._onDestroy))
    .subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get valid(): boolean {
    return true;
  }

  get empty() {
    return false;
  }

  @Input()
  get value(): any { return this._value; }
  set value(value: any) {
    this._value = value;
    this.stateChanges.next();
  }
  private _value: any;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
  }

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
