<h1 matDialogTitle>
  {{ 'inputAnalysis.dialog.title' | translate }}
  <span class="spacer"></span>
  @if (inputAnalysis.analyzing) {
    <mat-icon color="primary"
      @inOutAnimation
      class="rotating"
      [matTooltip]="'common.computing' | translate"
    >
      loop
    </mat-icon>
  }
</h1>

<mat-dialog-content class="dark-vertical-scrollbar"
  resizeObserver
  (resize)="dialogContentHeight.next($event.contentRect.height)"
  [style.--mat-dialog-content-height.px]="dialogContentHeight | async"
>

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight [(selectedIndex)]="tabIndex">
    <mat-tab [label]="'inputAnalysis.dialog.overview.title' | translate" bodyClass="custom-padding" >

      <p>{{ 'inputAnalysis.dialog.description' | translate }}</p>

      <mat-action-list>
        @for (x of categoriesDescription; track $index) {
          <mat-list-item class="dynamic-height" (click)="tabIndex = $index + 1">
            <mat-icon matListItemIcon [ngClass]="x.class">{{ x.icon }}</mat-icon>
            <span matListItemTitle>{{ x.title | translate }}</span>
            <span>{{ x.description| translate }}</span>
          </mat-list-item>
        }
      </mat-action-list>

    </mat-tab>

    @for (x of categoriesDescription; track $index) {
      <mat-tab [label]="(x.title | translate) + (inputAnalysis[x.numberKey] > 0 ? ' (' + inputAnalysis[x.numberKey] + ')' : '')">
        <ng-container *ngTemplateOutlet="remarksListRef; context: { category: x.key, remarks: inputAnalysis[x.key] }"></ng-container>
      </mat-tab>
    }

  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'common.close' | translate }}
  </button>
</mat-dialog-actions>


<ng-template #remarksListRef [templateTypes]="typeToken" let-category="category" let-remarks="remarks">
  @if (remarks.length) {
    <mat-accordion>
      @for (x of remarks; track trackByFn) {
        <mat-expansion-panel #panel [id]="x.id">
          <mat-expansion-panel-header>
            <mat-panel-title class="title" [innerHTML]="x.message | safe:'html'"></mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>


            @if ([errorCodes.eventDays, errorCodes.eventIntervals, errorCodes.linkedEventsDays, errorCodes.linkedEventsIntervals].includes(x.code)) {
              <!-- event does not fit in interval -->
              <div class="content-wrapper min-height-500">
                <div></div>
                @if (panel.expanded) {
                  <app-input-analyzer-event-intervals
                    [event]="$any(x.entities[0])"
                    [description]="'inputAnalysis.errors.' + x.code + '.description' | translate | paragraphs:'description'"
                  ></app-input-analyzer-event-intervals>
                }
              </div>
            }
            @else if (x.code == 'missing_overlap_species') {
              <!-- missing event species -->
              <div class="content-wrapper">
                @if (category == 'errors') {
                  <div [innerHTML]="'inputAnalysis.errors.' + x.code + '.description' | translate | paragraphs:'description'"></div>
                } @else {
                  <div [innerHTML]="x.explanation | paragraphs:'description'"></div>
                }

                <button mat-button
                        color="primary"
                        (click)="openOverlappableEventSetsDialog(x)"
                >
                  {{ 'inputAnalysis.etc.specify_forced_lesson_overlaps' | translate }}
                </button>
                @if (category == 'errors') {
                  {{ 'common.or' | translate | lowercase }}
                  <button mat-button
                          color="primary"
                          mat-dialog-close
                          routerLink="/schedule/{{ did }}/settings/options"
                          queryParamsHandling="merge">
                    {{ 'inputAnalysis.etc.schedule_settings' | translate }}
                  </button>
                }
              </div>
            }
            @else if (x.code == 'overloaded group') {
              <!-- overloaded group -->
              <div></div>
              @if (panel.expanded) {
                <app-input-analyzer-event-density
                  [group]="$any(x.entities[0])"
                  [description]="x.explanation | paragraphs:'description'"
                ></app-input-analyzer-event-density>
              }
            }
            @else if (x.code == 'overloaded group aborted') {
              <!-- overloaded group calculation aborted -->
              <div class="content-wrapper">
                <div [innerHTML]="x.explanation | paragraphs:'description'"></div>
              </div>
            }
            @else if (x.code == 'plannedDuration') {
              <!-- minutes per week mismatch -->
              <div class="content-wrapper">
                <div [innerHTML]="x.explanation | paragraphs:'description'"></div>
              </div>
            }
            @else if (x.code == 'empty_period' || x.code == 'overflowing_period') {
              <!-- empty period -->
              <div class="content-wrapper">
                <div [innerHTML]="x.explanation | paragraphs:'description'"></div>

                <button mat-button
                        color="primary"
                        mat-dialog-close
                        routerLink="/schedule/{{ did }}/settings/periods"
                        queryParamsHandling="merge">
                  {{ 'inputAnalysis.etc.modify_period' | translate }}
                </button>
              </div>
            }
            @else if (x.code == 'overflowing_holiday') {
              <!-- overflowing calendar exception -->
              <div class="content-wrapper">
                <div [innerHTML]="x.explanation | paragraphs:'description'"></div>

                <button mat-button
                        color="primary"
                        mat-dialog-close
                        routerLink="/schedule/{{ did }}/settings/holidays"
                        queryParamsHandling="merge">
                  {{ 'inputAnalysis.etc.modify_holiday' | translate }}
                </button>
              </div>
            }
            @else {
              <!-- other -->
              <div class="text-only" [innerHTML]="x.explanation | paragraphs:'description'"></div>
            }


          </ng-template>

        </mat-expansion-panel>
      }
    </mat-accordion>
  } @else {
    <div class="empty-container">
      <img src="/assets/images/svg/no_data_grey.svg"/>
      <span>{{ 'inputAnalysis.dialog.empty_category' | translate }}</span>
    </div>
  }
</ng-template>
