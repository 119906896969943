export const environment = {
  production:         true,
  stage:              false,
  development:        false,
  local:              false,
  isolated:           true,
  env:                "sdui",
  verbose:            false,
  built:              true, // = ng build
  API_BASE_URL:       "https://api.planner.sdui.app/",
  APP_BASE_URL:       "https://planner.sdui.app/",
  S3_BASE_URL:        "",
  RESOURCES_BASE_URL: "",
  timestamp: "2410011153"
};