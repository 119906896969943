import { Injectable             } from '@angular/core';
import { Router,
         Routes                 } from '@angular/router';
import { takeUntilDestroyed     } from '@angular/core/rxjs-interop';
import { BehaviorSubject        } from 'rxjs';
import { distinctUntilChanged,
         pairwise               } from 'rxjs/operators';

import { Role                   } from 'app/constants';
import { publicRoutes,
         privateRoutes,
         adminRoutes,
         partnerRoutes,
         restrictedRoutes,
         administratorRoutes,
         voidRoutes             } from 'app/core/routing/routes';


@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  private readonly _activeRoute$ = new BehaviorSubject<null | Routes>(null);

  constructor (
    private readonly router: Router
  ) {

    this._activeRoute$
    .pipe(
      distinctUntilChanged(),
      pairwise(),
      takeUntilDestroyed()
    )
    .subscribe(([prev, next]) => {
      if ( ! next) return;

      // update
      this.router.resetConfig(next);

      // load new view if the route config has changed
      // (if we do this also for the first route the query parameters somehow disappear...)
      if (prev) {
        void this.router.navigate([window.location.pathname], { queryParamsHandling: 'preserve' });
      }
    });

  }

  public setVoidRoutes(): void {
    this._activeRoute$.next(voidRoutes);
  }

  public setPublicRoutes(): void {
    this._activeRoute$.next(publicRoutes);
  }

  public setPrivateRoutes (role: Role): void {

    let newRoutes: Routes;
    if      (role == 'admin')         newRoutes = adminRoutes;
    else if (role == 'partner')       newRoutes = partnerRoutes;
    else if (role == 'administrator') newRoutes = administratorRoutes;
    else if (role == 'unrestricted')  newRoutes = privateRoutes;
    else if (role == 'restricted')    newRoutes = restrictedRoutes;
    else                              newRoutes = voidRoutes;

    this._activeRoute$.next(newRoutes);
  }

  public setAdminRoutes(): void {
    this._activeRoute$.next(adminRoutes);
  }

}