import { Component,
         AfterViewInit,
         OnDestroy,
         Inject                          } from '@angular/core';
import { Router                          } from '@angular/router';
import { saveAs                          } from 'file-saver';

import { environment                     } from '@env/environment';
import { AuthService,
         HttpService,
         LoggerService,
         PushNotificationService         } from 'app/core';
import { apiConstants,
         VIEW_ACCESS_TYPE,
         ViewAccessType                  } from 'app/constants';
import { Company,
         Division as _Division           } from 'app/shared/interfaces';
import { DataSourceService,
         DialogsService                  } from 'app/shared/services';
import { ActionButton                    } from 'app/shared/components/table-action-buttons/table-action-buttons.component';
import { TableCore                       } from '../table-core';


type Division = _Division.populated & {
  company: Pick<Company.complete, 'id' | 'name'>;
};

@Component({
  selector: 'app-divisions-table',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss'],
  providers: [
    DataSourceService
  ]
})
export class DivisionsComponent extends TableCore<Division> implements OnDestroy, AfterViewInit {
  protected readonly actionButtons: ActionButton<Division>[] = [];

  protected readonly appBaseUrl = environment.APP_BASE_URL;


  constructor (
    @Inject(VIEW_ACCESS_TYPE)
    protected readonly viewAccessType: ViewAccessType,
    protected dataSource:        DataSourceService<Division>,
    protected logger:            LoggerService,
    private   _dialog:           DialogsService,
    private   _auth:             AuthService,
    private   _http:             HttpService,
    private   _notification:     PushNotificationService,
    private   _router:           Router,
  ) {
    super(viewAccessType, dataSource, logger);

    this.columns = [
      'displayName',
      // 'active',
      'id',
      'organization',
      this.viewAccessType == 'admin' ? 'associatedPartner' : undefined,
      'editedAt',
      'createdAt',
      'actions'
    ];

    this.actionButtons = [
      {
        icon:   'login',
        label:  { translate: 'tables.admin-tables.divisions.actions.login' },
        action: (x) => { this.login(x) }
      },
      this.viewAccessType === 'admin' ? {
        icon:   'cloud_download',
        label:  { translate: 'tables.admin-tables.divisions.actions.download' },
        action: (x) => { this.download(x) }
      } : undefined,
      {
        icon:   'delete_outline',
        label:  { translate: 'tables.admin-tables.divisions.actions.delete' },
        action: (x) => { this.deleteOne(x) }
      }
    ];

  }

  ngAfterViewInit() {
    // cannot fetch data in constructor because of @ViewChild
    this.dataSource.init({
      url:       `${ this.urlRootDir }/${ apiConstants.DIVISIONS }`,
      paginator: this.paginatorComponent ?? undefined,
      sort:      this.sortComponent      ?? undefined,
      search:    this.searchComponent    ?? undefined,
      filter:    this.filterComponent    ?? undefined,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }


  protected deleteOne (div: Division) {
    this._dialog.openRemoveDialog()
    .subscribe((result) => {
      if ( ! result) return;

      this._http.delete(`${ this.urlRootDir }/${ apiConstants.DIVISIONS }/${ div.id }`, undefined)
      .subscribe({
        next:  () => this.dataSource.update(),
        error: (err) => {
          this.logger.error(err);
          this._notification.pushError({ translate: 'tables.admin-tables.divisions.actions.delete.failure' });
        }
      });
    });
  }


  protected login (div: Division) {
    this._http.get(`${ this.urlRootDir }/authenticate/company/${ div.company.id }`)
    .subscribe({
      next: res => {
        this._auth.login(res),

        // go to schedule
        this._router.navigate(['schedule', div.id, 'data']);
      },
      error: err => {
        this.logger.error(err);
        this._notification.pushError({ translate: 'tables.admin-tables.divisions.actions.login.failure' });
      }
    });
  }


  protected download (div: Division) {
    this._http.get(`${ this.urlRootDir }/${ apiConstants.DIVISIONS }/${ div.id }/file`, undefined)
    .subscribe({
      next: res => {
        let blob = new Blob([JSON.stringify(res, null, 2)], { type: 'application/json' });
        saveAs(blob,`input.json`);
      },
      error: () => this._notification.pushError()
    });
  }

}